import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const UpdateTheme = () => {
  const { id } = useParams();
  const [themeData, setThemeData] = useState({
    id: '',
    name: '',
    coverPhoto: '',
    description: '',
    createdAt: '',
    updatedAt: '',
    tags: '',
    version: '',
    price_id_stripe: '',
    urlThemes: '',
    category: 'Ecommerce', // Donne une valeur par défaut
    etat: 'archiver' // Donne une valeur par défaut
  });
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [fileContent, setFileContent] = useState(null);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchThemeData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_COMPONENT_URL || 'http://localhost:3001/api';
        const response = await axios.post(`${apiUrl}/themes/getinfotheme`, { id }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        setThemeData(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des données du thème:', error);
        setMessage('Erreur lors de la récupération des données du thème');
        setMessageType('error');
      }
    };

    fetchThemeData();
  }, [id, token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setThemeData({ ...themeData, [name]: value });
  };

  const handleCoverPhotoChange = (e) => {
    setCoverPhoto(e.target.files[0]);
  };

  const handleFileContentChange = (e) => {
    setFileContent(e.target.files[0]);
  };

  const validateForm = () => {
    const newErrors = {};
    const versionPattern = /^\d+\.\d+\.\d+$/;

    if (!themeData.name) {
      newErrors.name = 'Name is required';
    } else if (themeData.name.length > 50) {
      newErrors.name = 'Name must be at most 50 characters long';
    }

    if (!versionPattern.test(themeData.version)) {
      newErrors.version = 'Version must be in the format x.x.x';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    try {
      const apiUrl = process.env.REACT_APP_API_COMPONENT_URL || 'http://localhost:3001/api';
      const formDataToSend = new FormData();

      formDataToSend.append('id', themeData.id);
      formDataToSend.append('name', themeData.name);
      formDataToSend.append('coverPhoto', themeData.coverPhoto);
      formDataToSend.append('description', themeData.description);
      formDataToSend.append('tags', themeData.tags);
      formDataToSend.append('version', themeData.version);
      formDataToSend.append('price_id_stripe', themeData.price_id_stripe);
      formDataToSend.append('urlThemes', themeData.urlThemes);
      formDataToSend.append('category', themeData.category);
      formDataToSend.append('etat', themeData.etat);

      if (coverPhoto) {
        formDataToSend.append('coverPhoto', coverPhoto);
      }

      if (fileContent) {
        formDataToSend.append('file', fileContent);
      }

      const response = await axios.post(`${apiUrl}/themes/updatetheme`, formDataToSend, {
        headers: {
          'accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 200 || response.status === 201) {
        setMessage('Le thème a été mis à jour avec succès!');
        setMessageType('success');
      } else {
        setMessage(response.data.message || 'Une erreur est survenue lors de la mise à jour du thème.');
        setMessageType('error');
      }
    } catch (error) {
      setMessage('Une erreur est survenue lors de la mise à jour du thème.');
      setMessageType('error');
    }
  };

  return (
    <main className="mb-4">
      <section id="update" className="update">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={8}>
              <div className="section-title">
                <h2>Update Theme</h2>
              </div>
              {message && (
                <Alert variant={messageType === 'success' ? 'success' : 'danger'} className="mt-3">
                  {message}
                </Alert>
              )}
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="id">
                  <Form.Label>ID</Form.Label>
                  <Form.Control
                    type="text"
                    name="id"
                    value={themeData.id || ''}
                    readOnly
                    className="form-control-plaintext"
                  />
                </Form.Group>

                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={themeData.name}
                    onChange={handleChange}
                    isInvalid={!!errors.name}
                    required
                  />
                  <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="coverPhoto">
                  <Form.Label>Cover Photo</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={handleCoverPhotoChange}
                    className="form-control"
                  />
                </Form.Group>

                <Form.Group controlId="fileContent">
                  <Form.Label>File Content</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={handleFileContentChange}
                    className="form-control"
                  />
                </Form.Group>

                <Form.Group controlId="description">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    value={themeData.description}
                    onChange={handleChange}
                    rows="5"
                  />
                </Form.Group>

                <Form.Group controlId="tags">
                  <Form.Label>Tags</Form.Label>
                  <Form.Control
                    type="text"
                    name="tags"
                    value={themeData.tags}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="version">
                  <Form.Label>Version</Form.Label>
                  <Form.Control
                    type="text"
                    name="version"
                    value={themeData.version}
                    onChange={handleChange}
                    isInvalid={!!errors.version}
                  />
                  <Form.Control.Feedback type="invalid">{errors.version}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="price_id_stripe">
                  <Form.Label>Price ID Stripe</Form.Label>
                  <Form.Control
                    type="text"
                    name="price_id_stripe"
                    value={themeData.price_id_stripe}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="urlThemes">
                  <Form.Label>Theme URL</Form.Label>
                  <Form.Control
                    type="text"
                    name="urlThemes"
                    value={themeData.urlThemes}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="category">
                  <Form.Label>Category</Form.Label>
                  <Form.Control
                    as="select"
                    name="category"
                    value={themeData.category}
                    onChange={handleChange}
                  >
                    <option value="Ecommerce">Ecommerce</option>
                    <option value="Blog">Blog</option>
                    <option value="Vitrine">Vitrine</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="etat">
                  <Form.Label>Etat</Form.Label>
                  <Form.Control
                    as="select"
                    name="etat"
                    value={themeData.etat}
                    onChange={handleChange}
                  >
                    <option value="archiver">Archiver</option>
                    <option value="publier">Publier</option>
                  </Form.Control>
                </Form.Group>

                <div className="text-center mt-3">
                  <Button className="add-btn" type="submit">Update Theme</Button>
                </div>
                {message && (
                  <Alert variant={messageType === 'success' ? 'success' : 'danger'} className="mt-3">
                    {message}
                  </Alert>
                )}
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default UpdateTheme;
